<template>
  <!-- MAIN Div of the CICLO AUTO CONTROLLO DETAIL -->
  <div style="width: 100%" id="listener_cicloAutocontrollo">
    <!-- ALlegati dialogue -->
    <v-dialog content-class="artDialog" v-model="cicloautocontrollodialog" width="unset">
      <GestioneAllegato
        ref="componenteAllegati"
        controller_allegati="Global"
        controller_caratteristiche_tabella="Manutenzione"
        :id_riferimento="testaCommune.id_ciclo_auto_controllo"
        tipo_allegato="CICLO_AUTO_CONTROLLO"
        @snackbarOpen="snackbarOpen"
      />
    </v-dialog>
    <!-- MAIN HEADER OF COMPONENT -->
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">Ciclo Autocontrollo Dettaglio</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn light small style="margin-right: 5px">
        <v-icon color="primary">mdi-folder-image</v-icon>
      </v-btn>
      <v-btn light small style="margin-right: 5px">
        <v-icon color="primary">mdi-note-outline</v-icon>
      </v-btn>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="cicloautocontrollodialog = true"
            light
            small
          >
            <v-icon color="primary">mdi-paperclip</v-icon>
          </v-btn>
        </template>
        <span>Salva Allegati</span>
      </v-tooltip>
      <!-- BTN TO TRIgger SAVE ACTION -->
      <Button
        classe_buttone="ml-1"
        colore_icona="success"
        idForm="QUALITA"
        idOggetto="SALVA_CICLOAUTOCONTROLLO"
        mdi_icona="mdi-floppy"
        testo_tooltip="Salva"
        v-on:buttonClick="salvaCicloAutoControllo"
      />
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="clickChiudi" small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Torna al CicloAutocontrollo</span>
      </v-tooltip>
    </v-toolbar>
    <div>
      <!-- TESTACOMMUNE is COMMON COMPONENT USED BY THREE COMPONENT of Qualita -->
      <TestaCommuneNuova
        :key="testaCommune.id_ciclo_autocontrollo"
        v-on:update="getVal($event)"
        v-on:fmea_rev="getfmearev($event)"
        v-on:datagenerale="getdatagen($event)"
        v-on:articologenerale="getarticologen($event)"
        v-on:desgenerale="getdesgenerale($event)"
        v-on:codartgen="getcodartgen($event)"
        v-on:indicegenerale="getindicegenerale($event)"
        v-on:dumgen="getdumgen($event)"
        v-on:criticagen="getcriticagen($event)"
        v-on:idarticolo="getidarticolo($event)"
        v-on:fasiattgen="getfasiattgen($event)"
        v-on:fasiattsucc="getdesfasesuccessive($event)"
        v-on:des_fase_attuale="getdesfaseattuale($event)"
        v-on:fasesucc="getfasesucc($event)"
        v-on:macchinagen="getmacchinagen($event)"
        v-on:approvatogen="getapprovatogen($event)"
        v-on:testaCommuneChanged="testaCommuneChanged($event)"
        v-on:sendragione="sendragione($event)"
        v-on:sendidFronitore="sendidFronitore($event)"
        :testaCommune="testaCommune"
      />
      <v-row style="margin-left: 12px; margin-right: 12px; margin-top: 5px">
        <!-- DATA TABLE in order to Show the Caratteristiche -->
        <v-data-table
          style="width: 100%"
          :headers="headers_caratteristiche"
          :items="item_ciclo_auto_controllo"
          rounded
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar color="indigo" dense>
              <v-toolbar-title color="indigo" class="white--text"
                >Caratteristiche</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <Button
                classe_buttone="ml-1"
                colore_icona="primary"
                idForm="QUALITA"
                idOggetto="CREA_CICLOAUTOCONTROLLO_ELEMENT"
                mdi_icona="mdi-plus"
                testo_tooltip="Aggiungi Elemento"
                v-on:buttonClick="
                  dialog_gestione_record = true;
                  openNewDialog();
                "
              />
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.detail`]="{ item }">
            <Button
              classe_buttone="ml-1"
              colore_icona="primary"
              idForm="QUALITA"
              idOggetto="MODIFICA_CICLOAUTOCONTROLLO_ELEMENT"
              mdi_icona="mdi-pencil"
              testo_tooltip="Dettaglio"
              v-on:buttonClick="editRecord(item)"
            />
          </template>
          <template v-slot:[`item.miniatura`]="{ item }">
            <div
              :id="item.id_ciclo_autocontrollo_dettaglio"
              style="
                background-repeat: no-repeat;
                background-size: 100% 100%;
                border: 1px solid black;
                height: 100px;
                margin-top: 5px;
                width: 100px;
              "
            ></div>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <Button
              colore_icona="error"
              idForm="QUALITA"
              idOggetto="MODIFICA_CICLOAUTOCONTROLLO_ELEMENT"
              mdi_icona="mdi-window-close"
              testo_tooltip="Cancellare"
              v-on:buttonClick="deleteRecord(item)"
            />
          </template>
        </v-data-table>
      </v-row>
      <!-- V DIALOG TO CREATE OR UPDATE CARATTERTISTICHE -->
      <v-dialog v-model="dialog_gestione_record" width="800" flat>
        <div
          style="
            background-color: rgba(33, 33, 33, 0.46);
            bottom: 0;
            height: 100%;
            z-index: 2;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            width: 100%;
          "
          v-if="controllo_qualita_visible"
        >
          <div
            style="
              height: 90%;
              left: 5%;
              position: absolute;
              top: 5%;
              transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
              width: 90%;
            "
          >
            <Controllo
              @click_indietro="controllo_qualita_visible = false"
              :destinazione="'listener_cicloAutocontrollo'"
              :id_articolo="testaCommune.id_articolo"
              :riferimento="dettaglio_object.id_ciclo_autocontrollo_dettaglio"
            />
          </div>
        </div>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text" v-if="modifica == false"
            >Aggiungi elemento</v-toolbar-title
          >
          <v-toolbar-title class="white--text" v-else>Modifica elemento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="controllo_qualita_visible = true"
                color="#F3F3F3"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                small
                ><v-icon color="primary">mdi-paperclip</v-icon></v-btn
              >
            </template>
            <span>Allega file </span>
          </v-tooltip>
          <Button
            classe_buttone="ml-1"
            colore_icona="success"
            idForm="QUALITA"
            idOggetto="SALVA_CICLOAUTOCONTROLLO_ELEMENT"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="salvaCaratteristicha"
          />
          <v-spacer></v-spacer>
          <v-btn
            small
            light
            @click="
              (dialog_gestione_record = false),
                (tolleranza_selezionata_da = null),
                (tolleranza_selezionata_a = null)
            "
          >
            <v-icon color="red"> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-card-text style="max-height: 600px; overflow-y: auto">
            <v-form ref="form">
              <v-container>
                <v-row>
                  <v-col cols="4" class="py-0">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_RIGA"
                      label_input="Riga"
                      palceholder_input="Riga"
                      :vmodel="riga"
                      v-on:changeText="(val) => (this.riga = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col class="mt-1" cols="4"
                    ><v-autocomplete
                      dense
                      v-model="dettaglio_object.id_caratteristica"
                      :items="caratteristiche"
                      item-text="des"
                      item-value="id_tabella"
                      label="Caratteristiche"
                      placeholder="Caratteristiche"
                    >
                      <template #label>
                        <span class="">Caratteristiche</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="CARATTERISTICHE"
                            nome="Caratteristiche"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col class="mt-1" cols="4"
                    ><v-autocomplete
                      dense
                      v-model="dettaglio_object.id_fmea_modo_guasto"
                      :items="modi_di_guasto"
                      item-text="des"
                      item-value="id_tabella"
                      label="Modo di guasto"
                      placeholder="Modo di guasto"
                    >
                      <template #label>
                        <span class="">Modo di Guasto</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="MODO_DI_GUASTO"
                            nome="Modo di Guasto"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4"
                    ><v-autocomplete
                      dense
                      v-model="dettaglio_object.id_causa"
                      :items="cause_frequenze"
                      item-text="des"
                      item-value="id_tabella"
                      label="Causa"
                      placeholder="Causa"
                    >
                      <template #label>
                        <span class="">Causa</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="CAUSA"
                            nome="Causa"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4"
                    ><v-autocomplete
                      dense
                      v-model="dettaglio_object.id_strumento"
                      :items="strumenti"
                      item-text="des"
                      item-value="id_tabella"
                      label="Fam. strumento"
                      placeholder="Fam. strumento"
                    >
                      <template #label>
                        <span class="">Fam. Strumenti</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="STRUMENTI"
                            nome="Fam. Strumenti"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template> </v-autocomplete
                  ></v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      dense
                      v-model="dettaglio_object.id_criticita"
                      :items="criticita"
                      item-text="des"
                      item-value="id_tabella"
                      placeholder="Criticità"
                      label="Criticità"
                    >
                      <template #label>
                        <span class="">Criticità</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="CRITICITA"
                            nome="Criticità"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <TextField
                      :readonly="true"
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_TOLLERANZA"
                      label_input="Tolleranza"
                      palceholder_input="Tolleranza"
                      :vmodel="tolleranza"
                      v-on:changeText="(val) => (this.tolleranza = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col cols="4">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_VAL_MIN"
                      label_input="DA"
                      palceholder_input="DA"
                      :vmodel="valore_min"
                      v-on:changeText="(val) => (this.valore_min = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col cols="4">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_VAL_MAX"
                      label_input="A"
                      palceholder_input="A"
                      :vmodel="valore_max"
                      ref="valore_max"
                      v-on:changeText="(val) => (this.valore_max = val)"
                      :rules_input="[rules.min]"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_VALORE"
                      label_input="Valore"
                      palceholder_input="Valore"
                      :vmodel="valore"
                      v-on:changeText="(val) => (this.valore = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col cols="6">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_DES_CAMPIONE_MASTER"
                      label_input="Campione master"
                      palceholder_input="Campione master"
                      :vmodel="des_campione_master"
                      v-on:changeText="(val) => (this.des_campione_master = val)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_AUTO_CONTROLLO"
                      label_input="Auto controllo"
                      palceholder_input="Auto controllo"
                      :vmodel="auto_controllo"
                      v-on:changeText="(val) => (this.auto_controllo = val)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_CONTROLLO_SUPPLEMENTARE"
                      label_input="Controllo Sup."
                      palceholder_input="Controllo Sup."
                      :vmodel="controllo_supplementare"
                      v-on:changeText="(val) => (this.controllo_supplementare = val)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_CONTROLLO_QUALITA"
                      label_input="Controllo qualità"
                      palceholder_input="Controllo qualità"
                      :vmodel="controllo_qualita"
                      v-on:changeText="(val) => (this.controllo_qualita = val)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_AUTO_CONTROLLO_REG"
                      label_input="Auto controllo RG"
                      palceholder_input="Auto controllo RG"
                      :vmodel="auto_controllo_reg"
                      v-on:changeText="(val) => (this.auto_controllo_reg = val)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_CONTROLLO_SUPPLEMENTARE_REG"
                      label_input="Controllo Sup. RG"
                      palceholder_input="Controllo Sup. RG"
                      :vmodel="controllo_supplementare_reg"
                      v-on:changeText="(val) => (this.controllo_supplementare_reg = val)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_CONTROLLO_QUALITA_REG"
                      label_input="Controllo qualità RG"
                      palceholder_input="Controllo qualità RG"
                      :vmodel="controllo_qualita_reg"
                      v-on:changeText="(val) => (this.controllo_qualita_reg = val)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <TextField
                      idForm="CICLOAUTOCONTROLLO"
                      idOggetto="CA_FREQUENZA"
                      label_input="Frequenza"
                      palceholder_input="Frequenza"
                      :vmodel="frequenza"
                      v-on:changeText="(val) => (this.frequenza = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col cols="4" class="mt-4"
                    ><v-autocomplete
                      dense
                      v-model="dettaglio_object.id_um_frequenza"
                      :items="unita_misura_controllo"
                      item-text="des"
                      item-value="id_tabella"
                      label="UM Frequenza"
                      placeholder="UM Frequenza"
                    >
                      <template #label>
                        <span class="">Unità Misura Controllo</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="UNITA_MISURA_CONTROLLO"
                            nome="Unità Misura Controllo"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template> </v-autocomplete
                  ></v-col>
                  <v-col cols="6">
                    <v-textarea
                      outlined
                      rows="2"
                      label="Note"
                      placeholder="Note"
                      v-model="note"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-overlay :value="overlay" style="z-index: 10">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <!-- SNACK BAR IN ORDER TO SHOW THE ALERT OF THE COMPONENT -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import GestioneAllegato from "../../TOOLS/GestioneAllegato.vue";
import TestaCommuneNuova from "../TestaCommuneNuova.vue";
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "@/components/TOOLS/FormComponentPermissions/TextField.vue";
// IMPORTING COMMON COMPONENT
export default {
  components: {
    Controllo,
    // Generic COMPONENT IN order to SHOW List in V-AUTOCOMPLETE
    Modules: () => import("@/components/TOOLS/Modules"),
    Button,
    TestaCommuneNuova,
    GestioneAllegato,
    TextField,
  },
  data() {
    return {
      rules: {
        // RULE in order to keep track of A VALUE
        min: (value) => {
          if (this.valore_min != null) {
            if (this.valore_min != null && value != null) {
              return parseInt(value) > parseInt(this.valore_min);
            } else return false;
          } else return true;
        },
      },
      controllo_qualita_visible: false,
      overlay: false,
      // SelectedElement
      sel_elem: {},
      // Deleted Ciclo autocontrollo
      deletedCara: [],
      allegati: [],
      // SnackBar Value
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      // first variable of the caratterische dialog
      riga: "",
      tolleranza: "",
      valore_min: "",
      valore_max: "",
      valore: "",
      des_campione_master: "",
      auto_controllo: "",
      controllo_supplementare: "",
      controllo_qualita: "",
      auto_controllo_reg: "",
      controllo_supplementare_reg: "",
      controllo_qualita_reg: "",
      frequenza: "",
      //CUSTOM OBJECT OF THE CARATTERISTICA OBJECT
      dettaglio_object: {
        id_ciclo_autocontrollo_dettaglio: -1,
        id_ciclo_autocontrollo: -1,
        id_caratteristica: null,
        id_criticita: null,
        id_fmea: null,
        id_fmea_modo_guasto: null,
        id_difetto: null,
        id_fmea_causa: null,
        id_causa: null,
        id_strumento: null,
        des_strumento: null,
        cod_strumento: null,
        des_campione_master: null,
        des_guasto: null,
        auto_controllo: null,
        controllo_supplementare: null,
        controllo_qualita: null,
        riga: null,
        tolleranza: null,
        valore_min: null,
        frequenza: null,
        id_um_frequenza: null,
        valore_max: null,
        auto_controllo_reg: null,
        controllo_supplementare_reg: null,
        controllo_qualita_reg: null,
        valore: null,
        note: null,
        validita: null,
        date_ins: null,
        date_mod: null,
        miniatura: -1,
        id_utente: null,
      },
      // DATA Use to communicate between the TestCommune
      main_info_object: {
        id_ciclo_autocontrollo: -1,
        num_ciclo_autocontrollo: null,
        data_ciclo_autocontrollo: null,
        descrizione: null,
        stato: null,
        des_stato: null,
        id_articolo: null,
        cod_articolo_esterno: null,
        cod_articolo: null,
        des_articolo: null,
        indice_modifica: null,
        dum: null,
        id_macchina: null,
        id_ciclo_attuale: null,
        id_ciclo_successivo: null,
        id_fase_attuale: null,
        id_fase_successivo: null,
        id_criticita: null,
        id_firmatario: null,
        nominativo_firmatario: null,
        id_fmea: null,
        num_fmea: null,
        rev_fmea: null,
        id_fmea_fasi: null,
        id_fmea_fase_collaudo: null,
        num_revisione: null,
        id_fornitore: null,
        codice_fornitore: null,
        ragione_sociale: null,
        note_interne: null,
        annotazione: null,
        validita: null,
        date_ins: null,
        date_mod: null,
        id_utente: null,
        des_fase_attuale: null,
        des_fase_successivo: null,
      },
      // Main INFo Object
      dialog_gestione_articoli: false,
      // EMpty object to sent to Common Component
      testaCommune: {},
      cicloautocontrollodialog: false,
      // Header of Caratteristiche Tabella
      headers_caratteristiche: [
        {
          text: "",
          value: "detail",
          sortable: false,
        },
        {
          text: "Riga",
          value: "riga",
          align: "center",
          sortable: false,
        },
        {
          text: "Tipo carat.",
          value: "des_caratteristica",
          align: "center",
          sortable: false,
        },
        {
          text: "Valore carat.",
          value: "valore",
          align: "center",
          sortable: false,
        },
        {
          text: "Guasto",
          value: "des_guasto",
          align: "center",
          sortable: false,
        },
        {
          text: "Causa",
          value: "des_causa",
          align: "center",
          sortable: false,
        },
        {
          text: "Strumento",
          value: "des_strumento",
          align: "center",
          sortable: false,
        },
        {
          text: "Criticità",
          value: "des_criticita",
          align: "center",
          sortable: false,
        },
        {
          text: "Tolleranza",
          value: "tolleranza",
          align: "center",
          sortable: false,
        },
        {
          text: "Da",
          value: "valore_min",
          align: "center",
          sortable: false,
        },
        {
          text: "A",
          value: "valore_max",
          align: "center",
          sortable: false,
        },
        {
          text: "Campione master",
          value: "des_campione_master",
          align: "center",
          sortable: false,
        },
        {
          text: "Frequenza",
          value: "frequenza",
          align: "center",
          sortable: false,
        },
        {
          text: "UM Frequenza",
          value: "des_um_frequenza",
          align: "center",
          sortable: false,
        },
        {
          text: "RG",
          value: "controllo_supplementare",
          align: "center",
          sortable: false,
        },
        {
          text: "Controllo Qualità",
          value: "controllo_qualita",
          align: "center",
          sortable: false,
        },
        {
          text: "Auto controllo RG",
          value: "auto_controllo_reg",
          align: "center",
          sortable: false,
        },
        {
          text: "Controllo Sup. RG",
          value: "controllo_supplementare_reg",
          align: "center",
          sortable: false,
        },
        {
          text: "Controllo qualità RG",
          value: "controllo_qualita_reg",
          align: "center",
          sortable: false,
        },
        {
          text: "Note",
          value: "note",
          align: "center",
          sortable: false,
        },
        {
          text: "Miniatura",
          value: "miniatura",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "delete",
          sortable: false,
        },
      ],
      localObject: {},
      item_ciclo_auto_controllo: [],
      dialog_gestione_record: false,
      modifica: false,
      note: "",
      // Causa Lista
      cause_frequenze: [],
      // Guasto Lista
      modi_di_guasto: [],
      // Caratteristicha Lista
      caratteristiche: [],
      // Strumenti Lista
      strumenti: [],
      // Criticita Lista
      criticita: [],
      // UM frquenza Lista
      unita_misura_controllo: [],
    };
  },
  methods: {
    aggiungiImg(messaggio) {
      this.dialog_gestione_record = false;
      /* Recupero l'immagine nel messaggio
       * che è un base64 non splittato. */
      let photo = messaggio.detail.img;
      // Uso il base64 per valorizzare la src di un oggetto immagine
      let img = new Image();
      img.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il div in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 100 px; se lo sono scalo le dimesioni.*/
        if (img.width > img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(messaggio.detail.id).style.width = width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height = height_nuova + "px";
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(messaggio.detail.id).style.width = width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height = height_nuova + "px";
        } else {
          document.getElementById(messaggio.detail.id).style.width = img.width + "px";
          document.getElementById(messaggio.detail.id).style.height = img.height + "px";
        }
      };
      // Trasformo l'immagine del messaggio nello sfondo del mio div
      img.src = photo;
      document.getElementById(messaggio.detail.id).style.backgroundImage =
        "url('" + img.src + "')";
    },
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    // In order to save CicloAutoControlloDetail
    salvaCicloAutoControllo() {
      if (this.$refs.componenteAllegati != undefined) {
        if (!this.$refs.componenteAllegati.allegato_sel.des_allegato) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "È necessario des di allegato.";
          this.snackbar_text_color = "white";
          return;
        }
      }
      this.overlay = true;
      // This Condition Correspond to the Creation of New Ciclo Auto Controllo
      if (this.$route.params.foo == -1) {
        this.main_info_object.validita = 1;
        this.main_info_object.qm_ciclo_autocontrollo_dettaglio = this.item_ciclo_auto_controllo;
        let setPianoControllo = {
          controller: "qualita",
          method: "PUT",
          richiesta: {
            action: "setcicloautocontrollo",
            token: localStorage.user_token,
            qm_ciclo_autocontrollo: [this.main_info_object],
            id_ciclo_autocontrollo: -1,
          },
        };
        this.$store.dispatch("api", setPianoControllo).then((res) => {
          this.snackbar = true;
          this.overlay = false;
          if (res.status == 200 && this.$refs.componenteAllegati != undefined) {
            this.$refs.componenteAllegati.id_riferimento_creazione_genitore = res.data;
            this.$refs.componenteAllegati.salvaTuttiAllegati();
          }
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          this.$router.push({
            name: "Qualita",
            params: {
              comp: 2,
              id: this.main_info_object.id_articolo,
            },
          });
        });
      }
      // This is logic for Updating A ciclo auto controllo
      if (this.$route.params.foo > 0) {
        if (this.deletedCara.length > 0) {
          this.deletedCara.map((it) => {
            this.item_ciclo_auto_controllo.push(it);
          });
        }
        this.testaCommune.id_articolo = this.id_articolo
        this.testaCommune.qm_ciclo_autocontrollo_dettaglio = this.item_ciclo_auto_controllo;
        let setPianoControllo = {
          controller: "qualita",
          method: "PUT",
          richiesta: {
            action: "setcicloautocontrollo",
            token: localStorage.user_token,
            qm_ciclo_autocontrollo: [this.testaCommune],
            id_ciclo_autocontrollo: this.testaCommune.id_ciclo_autocontrollo,
          },
        };
        this.$store.dispatch("api", setPianoControllo).then((res) => {
          if (res.status == 200 && this.$refs.componenteAllegati != undefined) {
            this.$refs.componenteAllegati.salvaTuttiAllegati();
          }
          this.snackbar = true;
          this.overlay = false;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          this.$router.push({
            name: "Qualita",
            params: {
              comp: 2,
              id: this.testaCommune.id_articolo,
            },
          });
        });
      }
    },
    ///////////////////////////// Following are Functions Getting All Values from the Shared Component
    getVal(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.numero_info = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.numero_info = ev;
      }
    },
    getdesfaseattuale(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.des_fase_attuale = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.des_fase_attuale = ev;
      }
    },
    getdesfasesuccessive(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.des_fase_successivo = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.des_fase_successivo = ev;
      }
    },
    getfmearev(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.fmea_rev = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.fmea_rev = ev;
      }
    },
    getdatagen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.data_ciclo_autocontrollo = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.data_ciclo_autocontrollo = ev;
      }
    },
    getarticologen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.cod_articolo = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.cod_articolo = ev;
      }
    },
    getdesgenerale(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.descrizione = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.descrizione = ev;
      }
    },
    getcodartgen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.cod_articolo_esterno = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.cod_articolo_esterno = ev;
      }
    },
    getidarticolo(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_articolo = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_articolo = ev;
      }
    },
    getindicegenerale(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.indice_modifica = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.indice_modifica = ev;
      }
    },
    getdumgen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.dum = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.dum = ev;
      }
    },
    getcriticagen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_criticita = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_criticita = ev;
      }
    },
    getfasiattgen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_fase_attuale = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_fase_attuale = ev;
      }
    },
    getfasesucc(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_fase_successivo = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_fase_successivo = ev;
      }
    },
    getmacchinagen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_macchina = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_macchina = ev;
      }
    },
    getapprovatogen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.nominativo_firmatario = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.nominativo_firmatario = ev;
      }
    },
    sendragione(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.ragione_sociale = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.ragione_sociale = ev;
      }
    },
    sendidFronitore(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_fornitore = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_fornitore = ev;
      }
    },
    ////////////////////////////////////Getting Values END Here/////////
    // Function is to open new MOdal for creation of New Caratteristiche
    openNewDialog() {
      (this.modifica = false), (this.riga = "");
      this.tolleranza = "";
      this.valore_min = null;
      this.valore_max = null;
      this.valore = "";
      this.des_campione_master = "";
      this.auto_controllo = "";
      this.controllo_supplementare = "";
      this.controllo_qualita = "";
      this.auto_controllo_reg = "";
      this.controllo_supplementare_reg = "";
      this.controllo_qualita_reg = "";
      this.frequenza = "";
      this.note = "";
      this.dettaglio_object = {
        id_ciclo_autocontrollo_dettaglio: -1,
        id_ciclo_autocontrollo: -1,
        id_caratteristica: null,
        des_caratteristica: null,
        id_criticita: null,
        des_criticita: null,
        id_um_frequenza: null,
        des_um_frequenza: null,
        id_fmea: null,
        id_fmea_modo_guasto: null,
        des_guasto: null,
        id_difetto: null,
        des_diffeto: null,
        id_fmea_causa: null,
        id_causa: null,
        des_causa: null,
        id_strumento: null,
        des_strumento: null,
        cod_strumento: null,
        des_campione_master: null,
        auto_controllo: null,
        controllo_supplementare: null,
        controllo_qualita: null,
        riga: null,
        tolleranza: null,
        valore_min: null,
        valore_max: null,
        auto_controllo_reg: null,
        controllo_supplementare_reg: null,
        controllo_qualita_reg: null,
        valore: null,
        note: null,
        frequenza: null,
        validita: 1,
        date_ins: null,
        date_mod: null,
        id_utente: null,
      };
    },
    // This is function to Use Getting the Value In form in edit mode
    editRecord(item) {
      this.sel_elem = item;
      this.modifica = true;
      this.dettaglio_object = item;
      this.riga = this.dettaglio_object.riga;
      this.tolleranza = this.dettaglio_object.tolleranza;
      this.valore_min = this.dettaglio_object.valore_min;
      this.valore_max = this.dettaglio_object.valore_max;
      this.valore = this.dettaglio_object.valore;
      this.des_campione_master = this.dettaglio_object.des_campione_master;
      this.auto_controllo = this.dettaglio_object.auto_controllo;
      this.controllo_supplementare = this.dettaglio_object.controllo_supplementare;
      this.controllo_qualita = this.dettaglio_object.controllo_qualita;
      this.auto_controllo_reg = this.dettaglio_object.auto_controllo;
      this.controllo_supplementare_reg = this.dettaglio_object.controllo_supplementare_reg;
      this.controllo_qualita_reg = this.dettaglio_object.controllo_qualita_reg;
      this.frequenza = this.dettaglio_object.frequenza;
      this.note = this.dettaglio_object.note;
      this.dialog_gestione_record = true;
    },
    // This is function is used in order to Save new or update existing Caratteristica
    salvaCaratteristicha() {
      // This is the control to check empty num riga
      if (this.riga === null || this.riga === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "È necessario specificare un numero di riga.";
        this.snackbar_text_color = "white";
        return;
      }
      // this is logic to create new caratteristica
      if (this.dettaglio_object.id_ciclo_autocontrollo > -1) {
        for (let index = 0; index < this.item_ciclo_auto_controllo.length; index++) {
          const element = this.item_ciclo_auto_controllo[index];
          if (element.riga == this.riga) {
            if (this.sel_elem != element) {
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar_text =
                "Non è possibile salvare due righe con lo stesso codice";
              return;
            }
          }
        }
        this.dettaglio_object.riga =
          this.riga == null ? this.dettaglio_object.riga : this.riga;
        this.dettaglio_object.tolleranza = this.tolleranza;
        this.dettaglio_object.valore_min = this.valore_min;
        this.dettaglio_object.valore_max = this.valore_max;
        (this.dettaglio_object.valore = this.valore),
          (this.dettaglio_object.des_campione_master = this.des_campione_master);
        this.dettaglio_object.auto_controllo = this.auto_controllo;
        this.dettaglio_object.controllo_supplementare = this.controllo_supplementare;
        this.dettaglio_object.controllo_qualita = this.controllo_qualita;
        this.dettaglio_object.auto_controllo_reg = this.auto_controllo_reg;
        this.dettaglio_object.controllo_supplementare_reg = this.controllo_supplementare_reg;
        this.dettaglio_object.controllo_qualita_reg = this.controllo_qualita_reg;
        this.dettaglio_object.frequenza = this.frequenza;
        this.dettaglio_object.note = this.note;
        // Here i am getting the values of ids in order to populate the table it will end with comment ending with number*
        if (this.dettaglio_object.id_caratteristica > -1) {
          this.caratteristiche.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_caratteristica) {
              this.dettaglio_object.des_caratteristica = it.des;
            }
          });
        } else {
          this.dettaglio_object.des_caratteristica = null;
          this.dettaglio_object.id_caratteristica = null;
        }
        if (this.dettaglio_object.id_fmea_modo_guasto > -1) {
          this.modi_di_guasto.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_fmea_modo_guasto) {
              this.dettaglio_object.des_guasto = it.des;
            }
          });
        } else {
          this.dettaglio_object.des_guasto = null;
          this.dettaglio_object.id_fmea_modo_guasto = null;
        }
        if (this.dettaglio_object.id_causa > -1) {
          this.cause_frequenze.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_causa) {
              this.dettaglio_object.des_causa = it.des;
            }
          });
        } else {
          this.dettaglio_object.des_causa = null;
          this.dettaglio_object.id_causa = null;
        }
        if (this.dettaglio_object.id_strumento > -1) {
          this.strumenti.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_strumento) {
              this.dettaglio_object.des_strumento = it.des;
            }
          });
        } else {
          this.dettaglio_object.des_strumento = null;
          this.dettaglio_object.id_strumento = null;
        }
        if (this.dettaglio_object.id_criticita > -1) {
          this.criticita.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_criticita) {
              this.dettaglio_object.des_criticita = it.des;
            }
          });
        } else {
          this.dettaglio_object.des_criticita = null;
          this.dettaglio_object.id_criticita = null;
        }
        if (this.dettaglio_object.id_um_frequenza > -1) {
          this.unita_misura_controllo.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_um_frequenza) {
              this.dettaglio_object.des_um_frequenza = it.des;
            }
          });
        } else {
          this.dettaglio_object.des_um_frequenza = null;
          this.dettaglio_object.id_um_frequenza = null;
        }
        //////////////////////**************************************/
        let i = this.item_ciclo_auto_controllo.findIndex(
          (it) => it == this.dettaglio_object
        );
        this.item_ciclo_auto_controllo.splice(i, 1, this.dettaglio_object);
        this.item_ciclo_auto_controllo.sort(
          (a, b) => parseFloat(a.riga) - parseFloat(b.riga)
        );
        this.dialog_gestione_record = false;
      } else {
        for (let index = 0; index < this.item_ciclo_auto_controllo.length; index++) {
          const element = this.item_ciclo_auto_controllo[index];
          if (element.riga == this.riga) {
            if (this.dettaglio_object != element) {
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar_text =
                "Non è possibile salvare due righe con lo stesso codice";
              return;
            }
          }
        }
        ////////////Here i am Creating a new Caratteristicha
        this.dettaglio_object.validita = 1;
        this.dettaglio_object.id_ciclo_autocontrollo = this.testaCommune
          .id_ciclo_autocontrollo
          ? this.testaCommune.id_ciclo_autocontrollo
          : -1;
        this.dettaglio_object.riga = this.riga;
        this.dettaglio_object.tolleranza = this.tolleranza;
        this.dettaglio_object.valore_min = this.valore_min;
        this.dettaglio_object.valore_max = this.valore_max;
        (this.dettaglio_object.valore = this.valore),
          (this.dettaglio_object.des_campione_master = this.des_campione_master);
        this.dettaglio_object.auto_controllo = this.auto_controllo
          ? this.auto_controllo
          : null;
        this.dettaglio_object.controllo_supplementare = this.controllo_supplementare
          ? this.controllo_supplementare
          : null;
        this.dettaglio_object.controllo_qualita = this.controllo_qualita
          ? this.controllo_qualita
          : null;
        this.dettaglio_object.auto_controllo_reg = this.auto_controllo_reg
          ? this.auto_controllo_reg
          : null;
        this.dettaglio_object.controllo_supplementare_reg = this
          .controllo_supplementare_reg
          ? this.controllo_supplementare_reg
          : null;
        this.dettaglio_object.controllo_qualita_reg = this.controllo_qualita_reg
          ? this.controllo_qualita_reg
          : null;
        this.dettaglio_object.frequenza = this.frequenza ? this.frequenza : null;
        this.dettaglio_object.note = this.note;
        if (this.dettaglio_object.id_caratteristica > -1) {
          this.caratteristiche.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_caratteristica) {
              this.dettaglio_object.des_caratteristica = it.des;
            }
          });
        } else {
          this.dettaglio_object.id_caratteristica = null;
          this.dettaglio_object.des_caratteristica = null;
        }
        if (this.dettaglio_object.id_fmea_modo_guasto > -1) {
          this.modi_di_guasto.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_fmea_modo_guasto) {
              this.dettaglio_object.des_guasto = it.des;
            }
          });
        } else {
          this.dettaglio_object.id_fmea_modo_guasto = null;
          this.dettaglio_object.des_guasto = null;
        }
        if (this.dettaglio_object.id_causa > -1) {
          this.cause_frequenze.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_causa) {
              this.dettaglio_object.des_causa = it.des;
            }
          });
        } else {
          this.dettaglio_object.id_causa = null;
          this.dettaglio_object.des_causa = null;
        }
        if (this.dettaglio_object.id_strumento > -1) {
          this.strumenti.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_strumento) {
              this.dettaglio_object.des_strumento = it.des;
            }
          });
        } else {
          this.dettaglio_object.id_strumento = null;
          this.dettaglio_object.des_strumento = null;
        }
        if (this.dettaglio_object.id_criticita > -1) {
          this.criticita.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_criticita) {
              this.dettaglio_object.des_criticita = it.des;
            }
          });
        } else {
          this.dettaglio_object.id_criticita = null;
          this.dettaglio_object.des_criticita = null;
        }
        if (this.dettaglio_object.id_um_frequenza > -1) {
          this.unita_misura_controllo.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_um_frequenza) {
              this.dettaglio_object.des_um_frequenza = it.des;
            }
          });
        } else {
          this.dettaglio_object.id_um_frequenza = null;
          this.dettaglio_object.des_um_frequenza = null;
        }
        this.dialog_gestione_record = false;
        const i = this.item_ciclo_auto_controllo.findIndex(
          (it) => it == this.dettaglio_object
        );
        if (i == 0) {
          this.item_ciclo_auto_controllo.splice(i, 1, this.dettaglio_object);
          this.item_ciclo_auto_controllo.sort(
            (a, b) => parseFloat(a.riga) - parseFloat(b.riga)
          );
        }
        if (i == -1) {
          this.item_ciclo_auto_controllo.push(this.dettaglio_object);
          this.item_ciclo_auto_controllo.sort(
            (a, b) => parseFloat(a.riga) - parseFloat(b.riga)
          );
        }
      }
    },
    // In order to delete single record
    deleteRecord(item) {
      if (item.id_ciclo_autocontrollo > -1) {
        item.validita = 0;
        this.deletedCara.push(item);
        let i = this.item_ciclo_auto_controllo.findIndex((it) => it == item);
        this.item_ciclo_auto_controllo.splice(i, 1);
      } else {
        let i = this.item_ciclo_auto_controllo.findIndex((it) => it == item);
        this.item_ciclo_auto_controllo.splice(i, 1);
      }
    },
    // In order to get all the value of autocomplete
    getTabelleAutocontrollo() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo:
            "'CARATTERISTICHE','MODO_DI_GUASTO','CRITICITA','CAUSA','STRUMENTI','UNITA_MISURA_CONTROLLO'",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.caratteristiche = res.data.CARATTERISTICHE;
        this.modi_di_guasto = res.data.MODO_DI_GUASTO;
        this.cause_frequenze = res.data.CAUSA;
        this.strumenti = res.data.STRUMENTI;
        this.criticita = res.data.CRITICITA;
        this.unita_misura_controllo = res.data.UNITA_MISURA_CONTROLLO;
      });
    },
    clickChiudi() {
      if (this.$route.params.foo > 0) {
        this.$router.push({
          name: "Qualita",
          params: {
            comp: 2,
            id: this.testaCommune.id_articolo,
          },
        });
      } else {
        this.$router.push({
          name: "Qualita",
          params: { comp: 2 },
        });
      }
    },
  },
  // Here i am controlling the Max value is bigger than min or not
  watch: {
    valore_min: {
      immediate: true,
      async handler() {
        if (this.$refs.form != undefined) {
          await this.$nextTick();
          this.$refs.form.validate();
        }
      },
    },
    // here we are calculating thr tolleranza
    valore_max: function () {
      const I = this.valore_max;
      this.tolleranza = parseFloat(this.valore_max) - parseFloat(this.valore_min);
      this[I] = !this[I];
    },
  },
  mounted() {
    document
      .getElementById("listener_cicloAutocontrollo")
      .addEventListener("nuova_img", this.aggiungiImg);
    this.getTabelleAutocontrollo();
    // Getting the Detail of the Ciclo AutoCOntollo
    if (this.$route.params.foo > 0) {
      this.overlay = true;
      let getCicloAutoControllo = {
        controller: "qualita",
        method: "POST",
        richiesta: {
          action: "getcicloautocontrollo",
          token: localStorage.user_token,
          id_ciclo_autocontrollo: this.$route.params.foo,
        },
      };
      this.$store.dispatch("api", getCicloAutoControllo).then((res) => {
        this.overlay = false;
          this.id_articolo = res.data.qm_ciclo_autocontrollo[0].id_articolo
        this.item_ciclo_auto_controllo =
          res.data.qm_ciclo_autocontrollo[0].qm_ciclo_autocontrollo_dettaglio;
        this.testaCommune = res.data.qm_ciclo_autocontrollo[0];
      });
    }
  },
};
</script>
